import React from 'react';
import { useTableOrMobile } from '../../hooks';
import { cannonStyled, useCannonStyletron } from '../../theme';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';

interface IResponsiveContainer {
  padded?: boolean;
  bottomLeft?: boolean;
  bottom?: boolean;
  fullHeight?: boolean;
  flexDirection?: 'column' | 'row';
  children?: React.ReactNode;
  $theme?: CannonSafeThemeT;
}

export default cannonStyled(
  'div',
  (props: IResponsiveContainer = { flexDirection: 'column' }) => ({
    width: '95%',
    height: props.fullHeight ? '100%' : 'auto',
    display: 'flex',
    flexDirection: props.flexDirection,
    alignItems: props.bottomLeft
      ? 'flex-start'
      : props.bottom
      ? 'flex-end'
      : 'center',
    justifyContent: props.bottomLeft ? 'flex-end' : 'center',
    maxWidth: '1280px',
  })
);
